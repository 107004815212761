<template>
  <div class="GrantApplicationPlatformForm-wrapper">
    <form-panel
      :submitText="submitText"
      :submitUrl="submitUrl"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccessFunc"
      ref="formPanel"
    >
      <col2-detail>
        <col2-block :title="title">
          <el-form-item v-show="!defaultRegionId" label="公司" prop="regionId" :rules="[{ required: true, message: '请选择公司', trigger: 'change' }]">
            <v-select2 v-model="form.regionId" placeholder="查询公司" v-bind="regionParams" @onChange="regionChange" />
          </el-form-item>
          <el-form-item label="授权应用">
            <chosen-list-panel :list.sync="form.subIds" textAs="subName" @select="showMultiSelect = true" />
          </el-form-item>
        </col2-block>
      </col2-detail>
      <template #saveBeforeSlot>
        <v-button v-if="showReturn" text="返回"  @click="returnClick" />
      </template>
    </form-panel>
    <multi-select
      title="应用列表"
      :isShow.sync="showMultiSelect"
      :searchUrl="getApplicationListURL"
      :headers="headers"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="form.subIds">
        <template #searchSlot>
          <v-input label="应用名称" v-model="searchParams.subName" />
          <v-select label="应用类别" v-model="searchParams.subHandle" :options="subHandleOps" />
          <v-select label="应用类型" v-model="searchParams.subType" :options="subTypeOps" />
          <v-input label="应用描述" v-model="searchParams.subNote" />
        </template>
    </multi-select>
  </div>
</template>

<script>
import { grantApplicationURL, getApplicationListURL, getGrantApplicationListURL } from './api'
import { subHandleMap, setSubHandleOps, subTypeMap, setSubTypeOps, statusMap } from '../map'
import { Col2Detail, Col2Block, ChosenListPanel, MultiSelect } from 'components/bussiness'
import { regionParams } from 'common/select2Params'

export default {
  name: 'GrantApplicationPlatformForm',
  components: {
    Col2Detail,
    Col2Block,
    ChosenListPanel,
    MultiSelect
  },
  props: {
    setBreadcrumb: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '设置公司授权应用'
    },
    submitText: String,
    showReturn: {
      type: Boolean,
      default: false
    },
    defaultRegionId: String,
    submitSuccess: Function
  },
  data () {
    return {
      subHandleOps: setSubHandleOps(1),
      subTypeOps: setSubTypeOps(1),
      regionParams,
      showMultiSelect: false,
      form: {
        regionId: '',
        subIds: []
      },
      submitUrl: grantApplicationURL,
      getApplicationListURL,
      headers: [
        {
          prop: 'subName',
          label: '应用名称'
        },
        {
          prop: 'subHandleText',
          label: '应用类别',
          formatter: row => subHandleMap[row.subHandle]
        },
        {
          prop: 'subTypeText',
          label: '应用类型',
          formatter: row => subTypeMap[row.subType]
        },
        {
          prop: 'subStatusText',
          label: '应用状态',
          formatter: row => statusMap[row.subStatus]
        },
        {
          prop: 'subNote',
          label: '应用描述'
        }
      ],
      searchParams: {
        subName: '',
        subHandle: undefined,
        subType: undefined,
        subNote: ''
      },
      responseParams: {
        id: 'id',
        name: 'subName'
      },
      responseKey: {
        id: 'id',
        name: 'subName'
      }
    }
  },
  created () {
    if (this.setBreadcrumb) {
      this.$setBreadcrumb('设置公司授权应用')
    }
  },
  watch: {
    defaultRegionId (newValue) {
      this.form.regionId = newValue
    }
  },
  methods: {
    regionChange (option) {
      let  _this = this
      const regionId = option && option.id
      if (regionId) {
        _this.$refs.formPanel.setSubmitDisabled(true)
        this.$axios.get(getGrantApplicationListURL, {
          params: {
            regionId
          }
        }).then(res => {
          if (res.status === 100) {
            const data = res.data || []
            this.form.subIds = data
            _this.$refs.formPanel.setSubmitDisabled(false)
          }
        })
      } else {
        this.form.subIds = []
      }
    },
    submitBefore (data) {
      let subIds = []
      data.subIds.forEach(item => {
        subIds.push(item.id)
      })
      data.subIds = subIds
      return true
    },
    submitSuccessFunc () {
      if (this.submitSuccess) {
        this.submitSuccess()
        return false
      }
      return true
    },
    returnClick () {
      this.$emit('returnClick')
    }
  }
}
</script>
